import "./about.css"
import PhotoCard from "../../components/photoCard/PhotoCard"

const About = () => {

  const imgLinks = {
                     "Prof. Liu":["images/chong.png", "https://cliulab.com/"], 
                     "Prof. Gu":["images/quanquan.png", "https://web.cs.ucla.edu/~qgu/"], 
                     "Prof. Costentin":["images/cyrille.png", "https://scholar.google.com/citations?user=2JpaV3IAAAAJ&hl=fr"],
                     "Benjamin Hoar":["images/ben.png", "https://www.linkedin.com/in/ben-hoar/"],
                     "Jingwen Sun":["images/jingwen.png", "https://www.linkedin.com/in/jingwen-sun-6b24b4200/"],
                     "Weitong Zhang":["images/weitong.png", "https://web.cs.ucla.edu/~weightzero/"]
                  }
  return (
    <div className="aboutWrapper">
      <h3 className="statHeader">About The Project</h3>
      <p className="aboutStatement">
         <em>Classify</em> is a tool developed through collaboration among the research groups of
         Professors Chong Liu (UCLA), Quanquan Gu (UCLA), and Cyrille Costentin (Université Grenoble Alpes). 
         With this tool, deep neural networks are utilized to provide researchers the means to automatically analyze cyclic
         voltammograms (CVs) to elucidate their underlying electrochemical mechanisms. This tool can provide percent
         certainties for five of the most fundamental mechanisms encountered in electrochemistry: <em>E</em>, <em>EC</em>, 
         {" "}<em>CE</em>, <em>ECE</em>, and <em>DISP1</em>. Performance and discussion of this approach was published in{" "} 
         <a href="https://pubs.acs.org/doi/10.1021/acsmeasuresciau.2c00045" className="sampleDoc" target="_blank" rel="noreferrer">
            <em>ACS Measurement Science Au</em>
         </a>
         {" "}in 2022.
      </p>
      <img src="images/aboutFig.png" className="aboutFig" alt="aboutFig"/>
      <div className="photoCards">
         {Object.keys(imgLinks).map(name => <PhotoCard key={name} image={imgLinks[name][0]} name={name} site={imgLinks[name][1]}/>)}
      </div>
    </div>
  )
}

export default About