import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './modelpicker.css'

const ModelPicker = () => {
   const [selectedModel, setSelectedModel] = useState(0)
   const navigate = useNavigate()
   const routeChange = () => {
       const newRoute = '/predictor'
       navigate(newRoute, {state:{modelID:selectedModel}})
   }

   return (
     <div className="pickWrapper">
       <h3 className="statHeader">Please Select Your Preferred Model</h3>
       <p className="">
          Details about the mechanisms can be found in the <a href="/stats" style={{color:'var(--violet', textDecoration:'none'}}>stats page.</a>
       </p>
       <div className="modelSelection">
          <div className="modelOption" onClick={() => {
            setSelectedModel(1) 
            routeChange()
            return
          }}>
             <h2 className="modelName">5 Class Model</h2>
             <p className="aboutModel">
                The 5 class model provides classification of cyclic voltammetry data into either the <em>E</em>,  <em>EC</em>,  <em>CE</em>,  <em>DISP1</em>, or  <em>ECE</em> classes. 
                If no surface bound redox active species or catalysis is anticipated, this model is likely
                to suit your needs. Your output will consist of a table of percentages relaying the likelihood
                that your data belongs to each of the five classes.
             </p>
          </div>
          <div className="modelOption" onClick={() => {
               setSelectedModel(1)
               routeChange()
               return 
          }}>
             <h2 className="modelName">9 Class Model (COMING SOON!)</h2>
             <p className="aboutModel">
                The 9 class model expands upon the classes provided by the 5 class model by adding
                classification certainties for the <em>SR</em>,  <em>ECP</em>,  <em>T1</em> and the  <em>DL</em> mechanisms. 
                This model is well suited for both greater uncertainty in the redox events present in your system
                or for systems where any form of catalysis is expected.
             </p>
          </div>
       </div>
     </div>
   )
 }

export default ModelPicker