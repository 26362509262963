import React from 'react'
import './navbar.css'
import NavLinks from './NavLinks'
import { FiMenu } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import { useState } from 'react'

const NavBar = () => {

  const [openBurger, setOpenBurger] = useState(false)

  const hamIcon = <FiMenu className="icon" size={25} onClick={() => {setOpenBurger(!openBurger); console.log(openBurger)}}/>
  const closeIcon = <CgClose className="icon" size={25} onClick={() => {setOpenBurger(!openBurger); console.log(openBurger)}}/>


  return (
    <nav className="navWrapper">
      <img src="images/title.png" alt="classifyimg" className="classifyImg"/>
      <nav className="bignav">
        <NavLinks />
      </nav>
      <div className="hammenu">
        {openBurger ? closeIcon : hamIcon}
        {openBurger &&
          <nav className="smallNav">
            <NavLinks />
          </nav>
        }
      </div>
    </nav>
  )
}

export default NavBar