import './welcome.css'
import { useNavigate } from 'react-router-dom'

const Welcome = () => {

  const navigate = useNavigate()
  const routeChange = () => {
      const newRoute = '/modelselect'
      navigate(newRoute)
  }
  return (
   <div className="welcomeWrapper">
      <h1 className="welcome">Welcome!</h1>
      <div className="welcomeStatement">
         <span>
            If you are new to this site and would like to learn more, head to our 
         </span>
         <a href="/about" className="sampleDoc"> about </a>
         <span>
            page. Otherwise, please follow the formatting guidelines given below before continuing. 
            You can also download{" "} 
         </span>
         <a href="/sample.csv" download="sample.csv" className="sampleDoc">
            this sample document
         </a>
         <span>
            {" "}for reference. Thank you!
         </span>
      </div>
      <ul className="dataReqs">
         <li className="listItem">Each experiment must have CV data measured at 6 unique scan rates</li>
         <li className="listItem">The left most column of your data must be the applied potentials</li>
         <li className="listItem">Column labels must follow the format "X V/s", (ex. 0.5 V/s)</li>
         <li className="listItem">Each column must have the same number of entries</li>
         <li className="listItem">Data must be saved in .csv format</li>
      </ul>
      <img className="formatEx" alt="legal format" src="images/dataFormat.png"/>
      <div className="welcomeButton" onClick={() => routeChange()}>Continue</div>
   </div>
  )
}

export default Welcome
