import NavBar from "./components/navbar/navbar";
import HomePage from "./pages/home/HomePage";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import StatPage from "./pages/stats/StatPage";
import ModelPicker from "./pages/modelpicker/ModelPicker";
import Predictor from "./pages/predictor/Predictor";
import Form from "./pages/form/Form";

import { 
  BrowserRouter as Router,
  Routes, 
  Route, 
} from 'react-router-dom'

function App() {
  return (
    <div>
      <NavBar />
      <div className="mainWrapper">
        <Router>
          <Routes>
            <Route path='/' element={<HomePage />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/stats' element={<StatPage />}/>
            <Route path='/modelselect' element={<ModelPicker />}/>
            <Route path='/predictor' element={<Predictor />}/>
            <Route path='/form' element={<Form />}/>
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
