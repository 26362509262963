import Uploader from "../../components/fileUpload/Uploader"
import PredTable from '../../components/predTable/PredTable'
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Graph from "../../components/graph/Graph"
const axios = require('axios').default

const Predictor = () => {
   const [files, setFiles] = useState([])
   const [submitted, setSubmitted] = useState(false)
   const [counter, setCounter] = useState(-1)
   const [graphs, setGraphs] = useState([])
   const [predData, setPredData] = useState([])
   const location = useLocation()
   const modelID = location.state.modelID

   useEffect(() => {
      const lcl_graphs = []
      for (let i = 0; i < Object.keys(files).length; i++) {
        lcl_graphs.push(<Graph files={files} index={i}/>)
      }
      setGraphs(lcl_graphs)
      setCounter(0)
    }, [files])
  
    const countMonitor = (x) => {
      if (counter === graphs.length-1 && x === 1) {
        setCounter(0)
      }
      else if (counter <= 0 && x === -1) {
        setCounter(graphs.length-1)
      }
      else {
        setCounter(counter + x)
      }
    }
  
    useEffect(() => {
      // const dummy_data = [
      //   {'CE': 0.0, 'EC': 22.4, 'E': 77.6, 'ECE': 0.0, 'DISP': 0.0, 'File': '6_2_CoTPP_10mM_1-BrBu_Slice_1_7.txt'}, 
      //   {'CE': 0.0, 'EC': 0.3, 'E': 0.0, 'ECE': 0.0, 'DISP': 99.7, 'File': 'File_17580.txt'},
      //   {'CE': 0.0, 'EC': 100.0, 'E': 0.0, 'ECE': 0.0, 'DISP': 0.0, 'File': 'File_43545.txt'},
      //   {'CE': 0.0, 'EC': 100.0, 'E': 0.0, 'ECE': 0.0, 'DISP': 0.0, 'File': 'File_43942.txt'}
      // ]
      //   const dummy_data = [
      //   {'SR':1.2, 'T1':10.4, 'ECP':30, 'DL':9.2, 'CE': 0.0, 'EC': 22.4, 'E': 77.6, 'ECE': 0.0, 'DISP': 0.0, 'File': '6_2_CoTPP_10mM_1-BrBu_Slice_1_7.txt'}, 
      //   {'SR':3.2, 'T1':10.2, 'ECP':30, 'DL':9.5, 'CE': 0.0, 'EC': 0.3, 'E': 0.0, 'ECE': 0.0, 'DISP': 99.7, 'File': 'File_17580.txt'},
      //   {'SR':3.2, 'T1':10.4, 'ECP':30, 'DL':8.1, 'CE': 0.0, 'EC': 100.0, 'E': 0.0, 'ECE': 0.0, 'DISP': 0.0, 'File': 'File_43545.txt'},
      //   {'SR':7.2, 'T1':15.4, 'ECP':30, 'DL':9.2, 'CE': 0.0, 'EC': 100.0, 'E': 0.0, 'ECE': 0.0, 'DISP': 0.0, 'File': 'File_43942.txt'}
      // ]
      const sendFiles = () => {
        if (files.length !== 0) {
          const formData = new FormData()
          Object.keys(files).forEach((file) => {
            formData.append(files[file].name, files[file])
          })
          formData.append("model", modelID)
          axios.post("/api/data", formData)
              .then(res => { 
                setPredData(res.data) 
              })
              .catch((err) => {
                console.log(err)
                alert("Something went wrong, please check your data format. We have an example on the homepage!")
                setFiles([])
              })
          //setPredData(dummy_data)
        }
      }
      sendFiles()
    }, [files, modelID])

   const navigate = useNavigate()
   const routeChange = () => {
      const newRoute = '/form'
      navigate(newRoute, {state: {tableData:predData}})
   }
  return (
      <div className="pageWrapper">
         <div className="aligner">
            <div className="preUploadSection">
               <div className="imagePanel">
               {!submitted && <img src='images/mechanisms/E.png' alt="" className="dummyPic"/>}
               {submitted && graphs[counter]}
               {submitted && 
                  <FaArrowCircleLeft className="img-last" onClick={() => {countMonitor(-1)}}/>
               }
               {submitted && 
                  <FaArrowCircleRight className="img-next" onClick={() => {countMonitor(1)}}/>
               }
               </div>
               <Uploader setFiles={setFiles} 
                        files={files} 
                        onSubmit={() => {setSubmitted(true); countMonitor(0)}}
                        selectedFile={files.length === 0 ? "" : files[counter].name}
               />
            </div>
            {submitted && predData.length !== 0 && 
            <div className="predTable">
               <PredTable realData={predData}/>
               <div className="submit" 
                     style={{marginTop:'10px', alignSelf:'flex-start', width:'200px'}}
                     onClick={() => routeChange()}>
               More Information
               </div>
            </div>
            }
         </div>
      </div>

  )
}

export default Predictor