import "./photocard.css"

const PhotoCard = ({ image, name, site }) => {
  return (
    <div className="photoCard">
      <a href={site} target="_blank" rel="noreferrer" style={{color:'var(--purple)'}}>
        <div className="imgWrapper">
          <img src={image} alt="propic" className="proPic"/>
          <span className="photoName"><em>{name}</em></span>
        </div>
      </a>
    </div>
  )
}

export default PhotoCard