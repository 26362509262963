import './footer.css'

const Footer = () => {
  return (
    <div className="footer">
      <div id="acknowledgement">
        <img src="images/nsf.png" alt="nsf" id="nsf"/>
        <p id='tystatement'>Thank you to the NSF for Funding this Work</p>
      </div>
      <p id='courtesy'>Website Courtesy of Chong Liu's Group – UCLA Chemistry and Biochemistry</p>
      <p id='courtesy2'>Liu Group – UCLA Chem</p>
    </div>
  )
}

export default Footer