import './mechs.css'

const Mechs = ({information}) => {

  const getTitle = () => {
      let title = information.title
      const spaceIndex = title.indexOf(' ')
      let mech = title.slice(0, spaceIndex)
      return <p className="mechName"><em>{mech}</em>{title.slice(spaceIndex)}</p>
  }

  return (
   <div className="mechContainer">
      {getTitle()}
      <div className="mechContent">
            <div className="content-overlay"></div>
            <img className="content-image" src={information.image} alt="mech"/>
            <div className="content-details fadeIn-top">
               <img src={information.mech} alt="rxnmech" className="mechImg" style={{width:information.steps}}/>
               <p>{information.desc}</p>
            </div>
      </div>
   </div>

  )
}

export default Mechs

/* <div className="mechCardWrapper">
      <p className="mechName">E Mechanism</p>
      <div className="mechCard">
         <img src="images/E.png" alt="mechpng" className="mechImage"/>
         <div className="overlay">
            <img src="images/E_rxn.png" alt="rxn"/>
            <p>This is some information about the reaction</p>
         </div>
      </div>
   </div> */