import React from 'react'
import './graph.css'
import { Line } from 'react-chartjs-2'
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
 } from 'chart.js';
import { useEffect, useState } from 'react';

 ChartJS.register(
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend
 );

 
 const Graph = ( {files, index} ) => {

   const [potentials, setPotentials] = useState([])
   const [currents, setCurrents] = useState([])

   useEffect(() => {
      if (files.length !== 0) {
         const reader = new FileReader()
         let res = ""
         reader.onload = (evt) => {
            res = evt.target.result
            let data = res.split(/[\n,\r]+/)
            if (data[data.length-1].length === 0) data = data.slice(0, data.length-1)
            data = data.slice(7, data.length)
            const potentials = []
            const currents = [[],[],[],[],[],[]]
            for (let i = 0; i < data.length; i++) {
              if (i % 7 === 0) potentials.push(potentials.length)
              else currents[(i%7)-1].push(Number(data[i]))
            }
            setPotentials(potentials)
            setCurrents(currents)
         }
         reader.readAsText(files[index])
      }
    }, [files, index])

   const getGraph = () => {
      const options = {
         responsive: true,
         aspectRatio: 1/1,
         layout: {
          padding: 0
         },
         plugins: {
           legend: {
             display: false
           },
           title: {
             display: false,
           },
         },
         scales: {
            x: {
              grid: {
                display: false,
                borderWidth: 5,
                borderColor: 'rgba(0,0,0,1)',
                z: 1,
              },
              ticks: {
               display: false,
              },
              suggestedMin: -20,
              //suggestedMax: potentials.length+1000,
            },
            y: {
              grid: {
                display: false,
                borderWidth: 5,
                borderColor: 'rgba(0,0,0,1)',
                z: 1,
              },
              ticks: {
               display: false
              }
            },
         },
         elements: {
            point: {
               radius: 0
            },
            line: {
               borderWidth: 3
            }
         },

      };

      const colors = [
        ['rgb(157, 156, 158)', 'rgba(157, 156, 158, 0.5)'], 
        ['rgb(199, 193, 201)', 'rgba(199, 193, 201, 0.5)'],
        ['rgb(193, 171, 201)', 'rgba(193, 171, 201, 0.5)'],
        ['rgb(184, 139, 191)', 'rgba(184, 139, 191, 0.5)'],
        ['rgb(111, 71, 156)', 'rgba(111, 71, 156, 0.5)'],
        ['rgb(39, 19, 77)', 'rgba(39, 19, 77, 0.5)']
      ]

      const datasets = []
      for (let i = currents.length-1; i >= 0; i--) {
        const cur_data = currents[i]
        datasets.push({
          'label':`forward ${i}`, 
          'data':cur_data.slice(0, cur_data.length/2),
          'borderColor':colors[i][0],
          'backgroundColor':colors[i][1],
        })
        datasets.push({
          'label':`reverse ${i}`, 
          'data':cur_data.slice(cur_data.length/2, cur_data.length).reverse(),
          'borderColor':colors[i][0],
          'backgroundColor':colors[i][1],
        })
      }

      const data = {
         labels: potentials.slice(0, potentials.length/2),
         datasets: datasets
      };
      return <div className="graph"><Line data={data} options={options}/></div>
    }

   return (
      <div className="">
         {potentials.length !== 0 && getGraph()}
      </div>
   );
 }
export default Graph
