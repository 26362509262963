import Welcome from "../../components/welcome/Welcome"
import './homepage.css'

/* I know this is weird, it's because something big was changed and I do not see 
   a good reason to spend time changing this */

const HomePage = () => {
  return (
    <div className="">
      <Welcome />
    </div>
  )
}

export default HomePage