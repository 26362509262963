import './predtable.css'

const PredTable = ({ realData }) => {
  return (
   <table className="resultsTable">
      <tbody>
         <tr>
            <th className="colName" key={'File'}>File</th>
            {Object.keys(realData[0]).map((label) => {
               if (label !=='File') {
                  return <th className="colName" key={label}>{label} %</th>
               }
               else return null
            })}
         </tr>
      {realData.map((item) => (
         <tr key={item.File} className="dataRow">
            <td className="entry" key={'file'}>{item.File}</td>
            {Object.keys(item).map((value, i) => {
               if (value !=='File') {
                  return <td className="entry" key={i}>{item[value]}</td>
               }
               else return null
            })}
         </tr> 
      ))}
      </tbody>
   </table>
  )
}

PredTable.defaultProps = {
   data: [],
}

export default PredTable

/*<th className="colName">File</th>
<th className="colName">E (%)</th>
<th className="colName">EC (%)</th>
<th className="colName">CE (%)</th>
<th className="colName">ECE (%)</th>
<th className="colName">DISP (%)</th>*/

/*<td className="entry">{item.File}</td>
<td className="entry">{item.E}</td>
<td className="entry">{item.EC}</td>
<td className="entry">{item.CE}</td>
<td className="entry">{item.ECE}</td>
<td className="entry">{item.DISP}</td>*/