import './form.css'
import { CSVLink } from 'react-csv'
import {useState} from 'react'
import { useLocation } from 'react-router-dom'
const axios = require('axios').default

const Form = () => {

   const [comment, setComment] = useState('')
   const [expectedMechs, setExpectedMechs] = useState({})
   const [downloaded, setDownloaded] = useState(false)
   const [submitted, setSubmitted] = useState(false)
   const location = useLocation()
   const data = location.state.tableData

   const files = []
   for (let i = 0; i < data.length; i++) {
      files.push(data[i]['File'])
   }

   const mechs = Object.keys(data[0])
   mechs.pop('File')
   const headers = [
      { label: "File", key: "File" },
   ]
   mechs.forEach((mech) =>  headers.push({ label: mech + ' %', key: mech}))
   mechs.push('N/A')

   const cellWidth = (1/mechs.length)*75

   const genCheckBoxes = (file) => {
      let row = []
      const name = file.toString()
      for (let i = 1; i < mechs.length+1; i++) {
         row.push(<td key={i} style={{width:`${cellWidth}%`}} className="tableCell">
                     <input type="radio" 
                            name={name}
                            value={mechs[i]}
                            onChange={(e) => mechChange(e, name)}>
                     </input>
                  </td>)
      }
      return row
   }

   const onSubmit = async (e) => {
      e.preventDefault()
      const data = new FormData()
      data.append("Feedback", comment)
      for (let i = 0; i < files.length; i++) {
         const predicted = files[i] in expectedMechs ? expectedMechs[files[i]] : "N/A"
         data.append(files[i], predicted)
      }
      setSubmitted(true)
      await axios.post("/api/form", data).catch((err) => console.log(err.message))
   } 

   const commentChange = (e) => {
      setComment(e.target.value)
   }

   const mechChange = (e, name) => {
      setExpectedMechs((prevState) => {
         prevState[name] = e.target.value
         return prevState
      })
   }
   
   return (
      <div className='formWrapper'>
         <h3 className="statHeader">Feedback Form</h3>
         <p>
         Thank you for submitting your data to CV Mechanism Predictor. Please, fill out the following
         form. For doing so, we will provide a download button for your results! The downloadable CSV will contain
         the same data as the table from the previous page (class predictions with percentage certainties by experiment).
         It is ok if you are unsure of the expected mechanisms for your experiments, just click N/A.
         </p>
         <form className="feedBackForm" onSubmit={onSubmit}>
            <table className='formTable' style={{width:mechs.length > 7 ? '100%' : '100%'}}>
               <tbody className='tableBody'>
                  <tr className='formRow'>
                     <th style={{width:'25%'}}>{ }</th>
                     {mechs.map((mech, i) => 
                        <th className="tableCell" key={i} style={{width:`${cellWidth}%`}}>
                           {mech}
                        </th>)}
                  </tr>
                  {files.map((file, i) => {
                     return (
                        <tr className='formRow' key={i}>
                           <td className='fileNames' style={{width:'25%', textAlign:'left'}}>{file}</td>
                           {genCheckBoxes(file)}
                        </tr>
                  )})}
               </tbody>
            </table>
            <textarea className="generalComments" 
                      placeholder="Leave any further comments here!" 
                      style={{width:mechs.length > 7 ? '100%' : '100%'}}
                      onChange={(e) => commentChange(e)}
                      value={comment}/>
            {!submitted && 
               <div className="">
                  <input type="submit" className="submit" value="Submit"/>
               </div>
            }
            {submitted && !downloaded && 
               <CSVLink className="submit" 
                        data={data} 
                        headers={headers} 
                        style={{textDecoration:'none'}}
                        filename={"your_results.csv"}
                        onClick={() => setDownloaded(true)}>
                  Download
               </CSVLink>
            }
            {downloaded &&
               <h1>Thank you!</h1>
            }
        </form>
      </div>
   )
}

export default Form