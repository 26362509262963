import './navbar.css'

const navLinks = () => {
  return (
   <ul className="navLinks">
      <a className="navLink" href="/"><li className="link">Home</li></a>
      <a className="navLink" href="/stats"><li className="link">Stats</li></a>
      <a className="navLink" href="/about"><li className="link">About</li></a>
   </ul>
  )
}
export default navLinks