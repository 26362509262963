import './uploader.css'
import { useState, useEffect } from 'react'

const Uploader = ({ files, setFiles, onSubmit, selectedFile }) => {
   const [addedFiles, setAddedFiles] = useState(false)

   const handleChange = (e) => {
      e.preventDefault()
      const files = e.target.files
      if (files.length === 0) {
         return
      }
      else if (files.length > 10) {
         alert("Only First Ten Files Considered!")
      }
      const sliced_files = Object.fromEntries(
         Object.entries(files).slice(0, 10)
     )
      setFiles(sliced_files)
      setAddedFiles(true)
   }

   useEffect(() => {
      const reset = () => {
         if (Object.keys(files).length === 0) {
            setAddedFiles(false)
         }
      }
      reset()
   }, [files])

   return (
      <div className="uploadWrapper">
         <div className="browseWrapper">
            <label htmlFor="fileFinder" className="browseButton">Select Files...</label>
            <input className="hiddenInput" 
                  id="fileFinder" 
                  type="file" multiple 
                  onChange={handleChange}
                  accept=".csv"
            />
         </div>
         {addedFiles &&
            <div className="fileMenu">
               <ul className="fileList">
                  {Object.keys(files).map((file) =>
                     <li className={files[file].name === selectedFile ? "highlighted" : "fileItem"} 
                         key={file}>{files[file].name}</li>
                  )}
               </ul>
               <div className="submit" onClick={() => onSubmit()}>Submit</div>   
            </div>
         }
      </div> 
   )
}

export default Uploader