import './statpage.css'
import Mechs from '../../components/mechs/Mechs'

const StatPage = () => {

  const mechanisms = {
    'E': {
      title:'E Mechanism',
      image:'images/mechanisms/E.png',
      mech:'images/mechanisms/E_rxn.png',
      steps:'40%',
      desc:'The E mechanism consists of a single, reversible, homogenous electron transfer reaction'
    },
    'EC': {
      title:'EC Mechanism',
      image:'images/mechanisms/EC.png',
      mech:'images/mechanisms/EC_rxn.png',
      steps:'40%',
      desc:'The EC mechanism consists of a single, reversible, homogenous electron transfer reaction followed by a reversible chemical reaction'
    },
    'CE': {
      title:'CE Mechanism',
      image:'images/mechanisms/CE.png',
      mech:'images/mechanisms/CE_rxn.png',
      steps:'40%',
      desc:'The CE mechanism consists of a single, reversible, homogenous electron transfer reaction preceded by a reversible chemical reaction'
    },
    'ECE': {
      title:'ECE Mechanism',
      image:'images/mechanisms/ECE.png',
      mech:'images/mechanisms/ECE_rxn.png',
      steps:'45%',
      desc:'The ECE mechanism consists of two reversible, homogenous electron transfer reactions with an intermediary, irreversible chemical reaction'
    },
    'DISP1': {
      title:'DISP1 Mechanism',
      image:'images/mechanisms/DISP1.png',
      mech:'images/mechanisms/DISP1_rxn.png',
      steps:'60%',
      desc:'The DISP1 mechanism consists of a reversible, homogenous electron transfer, an irreversible chemical reaction, and a terminal disproportionation reaction.',
    },
    'T1': {
      title:'T1 Mechanism',
      image:'images/mechanisms/T1.png',
      mech:'images/mechanisms/T1_rxn.png',
      steps:'40%',
      desc:'The T1 mechanism (T for Tafel) consists of a heterogenous irreversible electron transfer reaction',
    },
    'SR': {
      title:'SR Mechanism',
      image:'images/mechanisms/SR.png',
      mech:'images/mechanisms/SR_rxn.png',
      steps:'60%',
      desc:'The SR mechanism consists of a reversible, surface bound electron transfer reaction',
    },
    'ECP': {
      title:'ECP Mechanism',
      image:'images/mechanisms/ECP.png',
      mech:'images/mechanisms/ECP_rxn.png',
      steps:'50%',
      desc:'The ECP mechanisms consists of a reversible, catalyst activating electron transfer followed by a homogenous catalysis step (S for substrate, P for product)',
    },
  }

  const getColumns = (i, numCases) => {
    let numRows = Math.ceil(numCases / 3)
    let curRow = Math.floor(i/3)+1
    let remainders = numCases % 3
    if (curRow === numRows) {
      if (remainders === 2) {
        return i === numCases-1 ? '7/11' : '3/7'
      }
      else {
        return '5/9'
      }
    }
    i %= 3
    return `${(4*i)+1}/${4*i+5}`
  }  

  return (
    <div className="statWrapper">
      <h3 className="statHeader">Model Accuracy</h3>
      <p className="aboutStatement" style={{paddingTop:'0px'}}>
         The algorithm used for classification in this tool has an overall 
         <span className="accuracy"> accuracy of 98.5%</span>. This result is based on performance of the tool 
         when analyzing a batch of ~5000 samples after training of the neural networks. Below is the class-by-class
         accuracy presented in the form of a confusion matrix. Here, the classes are represented on the x and y-axis
         of the matrix and the percent accuracies are presented in the diagonal of the matrix. Errors are shown in
         the off-diagonal cells. For example, the last row, second column shows that 2% of <em>DISP1</em> cases
         were mistakenly predicted to represent an <em>EC</em> mechanism.
      </p>
      <img src="images/confMat.png" alt="confusion matrix" className="confMat"/>
      <h3 className="statHeader">Mechanism Details</h3>
      <p className="aboutStatement" style={{paddingBottom:'20px', paddingTop:'0px'}}>
         Information about each of the mechanisms can be found below. This info includes their CV traces (in common waveforms), 
         their reaction mechanisms, and brief descriptions regarding their underlying electrochemical mechanisms. 
         Only mechanisms with Faradaic processes are shown, the capacitive current only mechanism, DL (for double layer), is not shown and effectively
         serves as a blank in our 9 class model. All mechanisms are shown with oxidative primary redox events for simplicity, but the models
         can handle any valid CV data.
      </p>
      <div className="mechanismContainer">
        {Object.keys(mechanisms).map((mech, i) => {
          return <div className="mechHolder" style={{gridColumn:getColumns(i, Object.keys(mechanisms).length)}} key={mech}>
                    <Mechs information={mechanisms[mech]}/>
                </div>
        })}
      </div>
   </div>
  )
}

export default StatPage